<template>
	<div id="tu-work-handle">
		<el-dialog
			v-model="dialogVisible"
			:title="$t('home work')"
			width="35%"
			destroy-on-close
			:show-close="false">
			<div class="work-warp">
				<el-form v-if="sc==0" ref="form" :model="form" label-width="120px" label-position="top">
				    <el-form-item :label="$t('Due')">
						<el-date-picker 
							v-model="form.expire_date" 
							value-format="YYYY-MM-DD" 
							size="medium" 
							:editable="false" 
							type="date" 
							:placeholder="$t('Select Due')">
						</el-date-picker>
				    </el-form-item>
					<el-form-item :label="$t('Note')">
						<el-input v-model="form.note" :placeholder="$t('Note')" :rows="3" type="textarea" />
					</el-form-item>
					<el-form-item label="">
						<TuUpload ref="work" uAction="work" :splitOrNot="true" @start="onUploadStart" @success="onUploadSuccess" @progress="onUploadProgress" />
					</el-form-item>
			    </el-form>
				<el-form v-if="sc==1" ref="form" :model="form" label-width="120px" label-position="top">
					<el-form-item label="">
						<TuUpload ref="work" uAction="work" :splitOrNot="true" @start="onUploadStart" @success="onUploadSuccess" @progress="onUploadProgress" />
					</el-form-item>
			    </el-form>
			    <el-form v-if="sc==2" ref="form" :model="form" label-width="120px" label-position="top">
					<el-form-item :label="$t('Correct the content')">
						<el-input v-model="form.content" :placeholder="$t('Correct the content')" :rows="5" type="textarea" />
					</el-form-item>
					<el-form-item label="">
						<TuUpload ref="work" uAction="work" :splitOrNot="true" @start="onUploadStart" @success="onUploadSuccess" @progress="onUploadProgress" />
					</el-form-item>
			    </el-form>

			    <div class="work-detail" v-if="sc<2 || true">
					<el-table :data="tableData" style="width: 100%">
						<el-table-column prop="name" :label="$t('Name')" />
						<el-table-column prop="status" label="" width="150">
							<template #default="scope">
								<div v-if="scope.row.status == 1 && scope.row.progress > 0">
									<el-progress :percentage="scope.row.progress" striped-flow :text-inside="true" :stroke-width="20" style="width: 100%" />
								</div>
								<div v-else style="text-align: right;">
									<span>{{ scope.row.status == 1 ? $t('Uploading') : $t('Upload Success') }}</span>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="remove" label="" width="45">
							<template #default="scope">
								<div v-if="scope.row.status == 2">
									<span style="cursor: pointer;" @click="onRemoveWorkFile(scope.$index)">
										<el-icon><i class="fa fa-remove"></i></el-icon>
									</span>
								</div>
							</template>
						</el-table-column>
					</el-table>
			    </div>
			</div>
			
			<template #footer>
				<span class="dialog-footer">
					<!-- <el-button @click="handleAddAnother">ADD ANOTHER</el-button> -->
					<el-button @click="handleClose">{{ $t('cancel') }}</el-button>
					<el-button v-if="sc==0" type="primary" @click="handleConfirm">{{ $t('Create homework') }}</el-button>
					<el-button v-if="sc==1" type="primary" @click="handleUploadWork">{{ $t('Upload homework') }}</el-button>
					<el-button v-if="sc==2" type="primary" @click="handleCurrectWork">{{ $t('Current homework') }}</el-button>
				</span>
			</template>
	  	</el-dialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TuUpload from '@/components/TuUpload'
import WorkApi from '@/api/work'
import CourseApi from '@/api/course'

export default {
	name: 'WorkHandle',
	components: {
		TuUpload
	},
	props: {
		courseId: {
			type: [String, Number]
		}
	},
	data() {
		return {
			dialogVisible: false,
			form: {
				expire_date: null,
				note: '',
				content: ''
			},
			rules: {

			},
			tableData: [],
			info: {},
			sc: 0
		}
	},
	created() {
		
	},
	computed: {
		
	},
	methods: {
		// 打开预约课程弹框
		open(row) {
			if(!!row) {
				this.info = row;
				this.sc = row.sc_rate;
			}
			this.dialogVisible = true;
		},
		notHave(row) {
			CourseApi.notHomeWork({course_id: row.id}).then(res => {
				this.$emit('success', 1);
			})
		},
		close() {
			this.$emit('change');
			this.dialogVisible = false;
		},
		// 关闭是判断，是否提示
		handleClose() {

			this.close();
		},
		onUploadStart(value) {
			this.tableData.push({uid: value.uid, name: value.name, status: 1, progress: 0});
		},
		onUploadProgress(value) {
			for(let i in this.tableData) {
				if(this.tableData[i].uid == value.uid) {
					this.tableData[i].progress = value.progress;
					break;
				}
			}
		},
		onUploadSuccess(value) {
			this.tableData = this.tableData.map(function(val, key) {
				if(val.uid == value.uid) {
					val.status = 2;
					val.code = value.code;
					val.path = value.path;
				}
				return val;
			});
		},
		onRemoveWorkFile(index) {
			this.tableData.splice(index, 1);
		},
		// 确认保存预约课程
		handleConfirm() {
			let form = this.form;
			form.course_id = this.courseId;
			form.type = 2;
			if(this.tableData.length <= 0) {
				this.$message.warning(this.$t('Need to upload job file'));
				return ;
			}
			let list = [];
			for(let i in this.tableData) {
				if(!!!this.tableData[i].code) {
					this.$message.warning(this.$t('Need to upload job file'));
					return ;
				}
				list.push({code: this.tableData[i].code, name: this.tableData[i].name});
			}
			form.content = list;
			WorkApi.store(form).then(res => {
				this.$emit('success', 1);
				this.$message.success(this.$t('Curriculum Assignment Success'))
				this.form = {
					due: null,
					note: ''
				};
				this.tableData = [];
				this.close()
			})
		},
		handleUploadWork() {
			let form = {};
			form.course_id = this.courseId;
			form.type = 2;
			if(this.tableData.length <= 0) {
				this.$message.warning(this.$t('Need to upload job file'));
				return ;
			}
			let list = [];
			for(let i in this.tableData) {
				if(!!!this.tableData[i].code) {
					this.$message.warning(this.$t('Need to upload job file'));
					return ;
				}
				list.push({code: this.tableData[i].code, name: this.tableData[i].name});
			}
			form.content = list;
			WorkApi.submit(this.info.id, form).then(res => {
				this.$emit('success', 1);
				this.$message.success(this.$t('Curriculum Assignment Success'))
				this.tableData = [];
				this.close()
			})
		},
		handleCurrectWork() {
			let form = {};
			form.course_id = this.courseId;
			form.type = 2;

			let list = [];
			for(let i in this.tableData) {
				if(!!!this.tableData[i].code) {
					this.$message.warning(this.$t('Need to upload job file'));
					return ;
				}
				list.push({code: this.tableData[i].code, name: this.tableData[i].name});
			}
			form.content = list; 
			form.note = this.form.content;
			if(!!!form.content) {
				this.$message.warning(this.$t('Need to fill in the content of the correction work'));
				return ;
			}
			WorkApi.correct(this.info.id, form).then(res => {
				this.$emit('success', 1);
				this.$message.success(this.$t('Coursework marked successfully'))
				this.tableData = [];
				this.close()
			})
		}
	}
}
</script>
<style lang="less">
#tu-work-handle {
	.el-dialog {
		.el-dialog__body {
			padding: 0px 20px;
		}

		.el-form-item {
			margin-bottom: 8px;

			.el-form-item__label {
				padding: 0;
				font-weight: 600;
				line-height: 2;
			}

			.el-form-item__content {
				.el-select {
					width: 100%;
				}

				.el-input {
					width: 100%;
				}
			}
		}

		.work-warp {
			
		}
	}
}
</style>