<template>
	<div id="tu-course-detail">
		<el-row :gutter="20">
			<el-col :span="4">
		    <SecondMenu />
			</el-col>
			<el-col :span="20">
				<div class="warp" v-if="detail">
					<div class="desc-style">
						<div>
							{{ detail && detail.user_info ? detail.user_info.nickname : '' }}
						</div>
						<div><el-button size="mini" @click="$router.go(-1)">{{ $t('go-back') }}</el-button></div>
					</div>
					<el-descriptions v-if="detail">
					    <el-descriptions-item :label="$t('Learner') + ':' " v-if="$store.getters.userType==1">
					    	<el-tag>{{ detail.me_info.nickname }}</el-tag>
					    </el-descriptions-item>
					    <el-descriptions-item :label="$t('order-sn') + ':' ">{{ detail.order_sn }}</el-descriptions-item>
					    <el-descriptions-item :label="$t('Subject') + ':' ">{{ detail.subject.subject_name }}({{ detail.subject.level_name }})</el-descriptions-item>
					    <el-descriptions-item :label="$t('status') + ':' ">
					    	<el-tag type="info">{{ detail.status_name }}</el-tag>
					   	</el-descriptions-item>
					    <!-- <el-descriptions-item label="Pay Status: ">
					      <el-tag size="small">School</el-tag>
					    </el-descriptions-item> -->
							<el-descriptions-item :label="$t('course.content') + ':' ">
								{{ isStudent ? detail.content : (detail.tr_content || detail.content) }}
							</el-descriptions-item>
					 </el-descriptions>
				</div>
		  	<el-row :gutter="0" class="tu-row-div">
					<el-col :span="8" class="tu-row-col">
						<div class="cell" v-if="detail">
							<div>{{ detail.book.time }}</div>
							<div>{{ detail.book.mins }}</div>
							<div>{{ detail.book.min_time }} - {{ detail.book.max_time }}</div>
						</div>
					</el-col>
					<el-col :span="8" class="tu-row-col">
						<div class="cell" v-if="detail">
							<div class="money">{{ detail.currency }}{{ detail.price }}</div>
							<div>Amount</div>
						</div>
					</el-col>
					<el-col :span="8" class="tu-row-col">
						<div class="cell">
							<div>Tutoredin's Zoom</div>
							<div>
								<el-popover
							    placement="top-start"
							    :title="$t('copy')"
							    :width="200"
							    trigger="hover"
							    :content="$t('click-to-copy-zoom')"
							    v-if="detail && detail.zoom_number"
							  >
							    <template #reference>
							      <el-button @click="copyZoomNumber" type="primary">{{ detail.zoom_number || "No Zoom" }}</el-button>
							    </template>
							  </el-popover>
							  <el-button v-else>Closed</el-button>
							</div>
						</div>
					</el-col>
		  	</el-row>		  	
		  	<div class="warp" v-if="detail && Object.keys(detail.operation).length > 0">
					<el-row class="btn">
				    <el-button type="primary" v-if="detail.operation.is_edit" @click="dialogEditBooking = true">{{ $t('edit-booking') }}</el-button>
				    <el-button type="warning" v-if="detail.operation.is_close" @click="closeBooking">{{ $t('close-booking') }}</el-button>
				    <el-button type="warning" v-if="detail.operation.is_cancel" @click="cancelBooking">{{ $t('cancel-booking') }}</el-button>
				    <el-button type="success" v-if="detail.operation.is_pay" @click="payBooking">{{ $t('pay-booking') }}</el-button>
				    <el-button type="success" v-if="detail.operation.is_pass" @click="passBooking">{{ $t('pass-booking') }}</el-button>
				    <el-button type="danger" v-if="detail.operation.is_refuse" @click="refuseBooking">{{ $t('refuse-booking') }}</el-button>
				    <el-button type="info" v-if="detail.operation.is_appraise" @click="dialogAppraiseBooking = true">{{ $t('appraise-booking') }}</el-button>
				    <el-button type="warning" v-if="detail.operation.is_work==1" @click="workBooking(-1)">{{ $t('Not homework') }}</el-button>
				    <el-button type="danger" v-if="detail.operation.is_work==1" @click="workBooking">{{ $t('Create homework') }}</el-button>
				    <el-button type="danger" v-if="detail.operation.is_work==2" @click="workBooking">{{ $t('Upload homework') }}</el-button>
				    <el-button type="danger" v-if="detail.operation.is_work==3" @click="workBooking">{{ $t('Current homework') }}</el-button>
				  </el-row>
				</div>
				<div class="tu-work" v-if="detail && detail.work && detail.work.content">
					<div class="">
						<el-descriptions :title="$t('home work')" column="1">
							<el-descriptions-item :label="$t('date') + ':' ">{{ getFormatTime(detail.work.create_time) }}</el-descriptions-item>
							<el-descriptions-item :label="$t('Due') + ':' ">{{ getFormatTime(detail.work.expire_date) }}</el-descriptions-item>
							<el-descriptions-item :label="$t('Note') + ':' ">
								{{ detail.work.note }}
							</el-descriptions-item>
							<!-- <el-descriptions-item v-if="detail.work.record && detail.work.record.reply_content" :label="$t('Correct the content') + ':' ">
								{{ detail.work.record.reply_content }}
							</el-descriptions-item> -->
						</el-descriptions>
					</div>
					<div class="work-detail">
						<el-table :data="detail.work.content" border style="width: 100%">
							<el-table-column prop="name" :label="$t('attachment-list')">
								<template #default="scope">
									<el-link :href="scope.row.url" target="_blank" type="primary">{{ scope.row.name }}</el-link>
								</template>
							</el-table-column>
						</el-table>
						<br />
						<el-table v-if="detail.work.record && detail.work.record.content" :data="detail.work.record.content" border style="width: 100%">
							<el-table-column prop="name" :label="$t('student-homework')">
								<template #default="scope">
									<el-link :href="scope.row.url" target="_blank" type="primary">{{ scope.row.name }}</el-link>
								</template>
							</el-table-column>
						</el-table>
						<el-table v-if="detail.work.record && (detail.work.record.reply_content || detail.work.record.reply_note)" :data="correctData" border style="width: 100%">
							<el-table-column prop="name" :label="$t('Correct the content')">
								<template #default="scope">
									<template v-if="scope.row.reply_note">
										<div>{{ $t('Content') }}： {{ scope.row.reply_note }}</div>
									</template>
									<template v-else>
										<el-link :href="scope.row.url" target="_blank" type="primary">{{ scope.row.name }}</el-link>
									</template>
								</template>
							</el-table-column>
						</el-table>
				  </div>
				</div>
			</el-col>
		</el-row>
		<el-dialog
	    v-model="dialogEditBooking"
	    :title="$t('edit-booking')"
	    width="30%">
	    <el-date-picker
        v-model="bookDatetime"
        type="datetime"
        placeholder="Select date and time"
        format="YYYY-MM-DD HH:mm"
        style="width: 100%">
      </el-date-picker>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="dialogEditBooking = false">{{ $t('cancel') }}</el-button>
	        <el-button type="primary" @click="editBooking">{{ $t('Submit') }}</el-button>
	      </span>
	    </template>
	  </el-dialog>
	  <el-dialog
	    v-model="dialogAppraiseBooking"
	    :title="$t('appraise-booking')"
	    width="30%">
	    <el-form label-position="top">
	    	<el-form-item label="Score">
		    	<el-rate v-model="appraiseForm.star" size="large"></el-rate>
		    </el-form-item>
	    	<el-form-item :label="$t('appraise-content')">
		    	<el-input v-model="appraiseForm.content" type="textarea" rows="5"></el-input>
		    </el-form-item>
		  </el-form>
	    <template #footer>
	      <span class="dialog-footer">
	        <el-button @click="dialogAppraiseBooking = false">{{ $t('cancel') }}</el-button>
	        <el-button type="primary" @click="appraiseBooking">{{ $t('Submit') }}</el-button>
	      </span>
	    </template>
	  </el-dialog>	  
	  <WorkHandle ref='work' :course-id="id" @success="onWorkSuccess" />
		<input type="text" class="copy-input" ref="copyInput">
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { ElMessageBox } from 'element-plus'
import SecondMenu from '@/components/SecondMenu'
import CourseApi from '@/api/course'
import TimeControl from '@/utils/time-control'
import WorkHandle from '@/components/WorkHandle'

export default {
	name: "course-detail",
	components: {
		SecondMenu,
		WorkHandle
	},
	data() {
		return {
			id: 0,
			detail: null,
			dialogEditBooking: false,
			bookDatetime: '',
			dialogAppraiseBooking: false,
			appraiseForm: {
				course_id: 0,
				content: '',
				star: 5
			}
		}
	},
	computed: {
		...mapGetters([
			'isStudent'
		]),
		correctData() {
			let list = [];
			if(!!this.detail.work.record.reply_note) {
				list.push({name: this.detail.work.record.reply_note});
			}
			if(!!this.detail.work.record.reply_content) {
				for(let i in this.detail.work.record.reply_content) {
					list.push(this.detail.work.record.reply_content[i]);
				}
			}
			return list;
		}
	},
	created() {
		this.id = this.$route.params.id;
		if(this.id) {
			this.getApiDetailData();
		}
	},
	methods: {
		getApiDetailData() {
			if(!!!this.id || /^\d+$/.test(this.id) === false) {
				this.$message.error(this.$t('exception.request'))
				this.$router.push({path: '/course'})
				return ;
			}
			CourseApi.info(this.id).then((res) => {
				this.detail = res.data
				this.detail.book = {
					time: TimeControl.getFormatTime(this.detail.book_time),
					mins: this.detail.second/60 + ' mins',
					min_time: TimeControl.getFormatTime(this.detail.book_time, "HH:mm"),
					max_time: TimeControl.getFormatTime(this.detail.book_time + this.detail.second, "HH:mm")
				}
				// this.bookDatetime = new Date(this.detail.book_time*1000);
				this.bookDatetime = new Date(TimeControl.getFormatTime(this.detail.book_time, "YYYY-MM-DD HH:mm"));
				if(this.detail.appraise) {
					this.appraiseForm = this.detail.appraise;
				}
			});
		},
		copyZoomNumber() {
			let inputElement = this.$refs.copyInput
			inputElement.value = this.detail.zoom_number
			inputElement.select()
			document.execCommand('Copy')
			this.$message.success(this.$t('copy-success'))
		},
		editBooking() {
			if(!!!this.bookDatetime) {
				this.$message.error(this.$t('required.date'));
				return ;
			}
			let book_time = TimeControl.getUnixTime(this.bookDatetime);
			let now_time = TimeControl.getUnixTime(new Date);
			if(book_time <= now_time) {
				this.$message.error(this.$t('required.gt-date'));
				return ;
			}
			CourseApi.updateTime({course_id: this.id, start_date: book_time}).then(res => {
				this.$message.success(this.$t('required.date-update-ok'));
				this.getApiDetailData();
				this.dialogEditBooking = false;
			});
		},
		closeBooking() {
			ElMessageBox.confirm('Are you sure to close this book?', '', {
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				type: 'warning'
			}).then(() => {
				CourseApi.close(this.id).then(res => {
					this.$message.success('课程关闭成功');
					this.getApiDetailData();
				})
			}).catch(() => {

			})
		},
		cancelBooking() {
			ElMessageBox.confirm('Are you sure to cancel this book?', '', {
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				type: 'warning'
			}).then(() => {
				CourseApi.cancel(this.id).then(res => {
					let msg = this.$t('cancel-booking') + this.$t('Success');
					this.$message.success(msg);
					this.getApiDetailData();
				})
			}).catch(() => {

			})
		},
		payBooking() {
			this.$router.push({path: "/course/pay", query: {pid: this.detail.package_id, cid: this.id}});
		},
		passBooking() {
			ElMessageBox.confirm('Are you sure to pass this book?', '', {
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				type: 'warning'
			}).then(() => {
				let from = {
					stat: 1,
					ids: [this.id],
					reson: ''
				}
				CourseApi.verify(from).then(res => {
					let msg = this.$t('pass-booking') + this.$t('Success');
					this.$message.success(msg);
					this.getApiDetailData();
				})
			}).catch(() => {

			})
		},
		refuseBooking() {
			ElMessageBox.confirm('Are you sure to refuse this book?', '', {
				confirmButtonText: 'Yes',
				cancelButtonText: 'No',
				type: 'warning'
			}).then(() => {
				let from = {
					stat: 2,
					ids: [this.id],
					reson: ''
				}
				CourseApi.verify(from).then(res => {
					let msg = this.$t('refuse-booking') + this.$t('Success');
					this.$message.success(msg);
					this.getApiDetailData();
				})
			}).catch(() => {

			})
		},
		appraiseBooking() {
			this.appraiseForm.course_id = this.id;
			if(!!!this.appraiseForm.content) {
				this.$message.error(this.$t('required.enter-appraise-content'));
				return ;
			}
			CourseApi.appraise(this.appraiseForm).then(res => {
				let msg = this.$t('appraise-booking') + this.$t('Success');
				this.$message.success(msg);
				this.getApiDetailData();
				this.dialogAppraiseBooking = false
			});
		},
		workBooking(value) {
			if(value == -1) {
				this.$refs.work.notHave(this.detail);
			} else {
				this.$refs.work.open(this.detail.work);
			}
		},
		getFormatTime(value) {
			return TimeControl.getFormatTime(value, 'L');
		},
		onWorkSuccess() {
			this.getApiDetailData();
		}
	}
}
</script>
<style lang="less">
#tu-course-detail {
	.warp {
		border: 1px solid #CCC;
	  padding: 15px 10px;
	  margin-right: 2px;

	  .desc-style {
	  	display: flex;
	  	justify-content: space-between;
	  	padding: 12px 0;

	  	div:first-child {
	  		color: var(--el-color-primary);
	  		font-size: 21px;
	  	}
	  }

	  .btn {
	  	justify-content: flex-end;
	  }
	}

	.tu-row-div {
		.tu-row-col {
			border: var(--el-border-base);
			margin-right: -1px;
			margin-top: -1px;
			margin-bottom: -1px;

			.cell {
				display: flex;
		    align-items: center;
		    color: gray;
		    flex-direction: column;
		    height: 290px;
		    justify-content: center;
		    line-height: 1.8;
			}

			.money {
				font-size: 60px;
				font-weight: var(--el-font-weight-primary);
			}
		}
	}

	.copy-input{
		width: 10px;
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		z-index: -10;
	}

	.tu-work {
		border: 1px solid #CCC;
	  padding: 15px 10px;
	  border-top: 0;
	}
}
</style>